import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"
import { color } from "../_globalSettings/skin"
import { typography } from "../_globalSettings/typography"

export const StyledMain = styled.div`
	padding-top: 140px;
	padding-bottom: 102px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	position: relative;
	width: 100%;
	h1 {
		padding-bottom: 0;
	}
	.card__main {
		display: flex;
		justify-content: space-between;
	}
	.card__bottom {
		margin-top: 58px;
		text-align: center;
	}
	.card__wrapper {
		width: 35%;
	}
	.card__bottom-text {
		color: ${color.main.text2};
		font-size: 14px;
		line-height: 110%;
		font-weight: 500;
		letter-spacing: 0.3em;
		text-transform: uppercase;
	}
	.card__bottom-arrow {
		position: absolute;
		bottom: -68px;
		left: calc(50% - 10px);
	}
	.card__headline {
		font-size: 48px;
		line-height: 50px;
		font-weight: 500;
		padding-bottom: 15px;
		position: relative;
		color: ${color.black.default};
		&:before {
			content: "";
			display: inline-block;
			border-bottom: 4px solid ${color.blue.default};
			width: 20px;
			margin-right: 5px;
			position: absolute;
			left: -25px;
			top: 40px;
		}
	}
	.card__link {
		margin-bottom: 32px;
	}
	.card__text {
		padding-bottom: 32px;
		color: ${color.main.text2};
		${typography.BodyXXL};
		margin-top: 40px;
	}
	.card__children {
		padding-top: 34px;
		width: 52%;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		padding-top: 40px;
		padding-bottom: 94px;
		.card__main {
			flex-direction: column;
		}
		.card__children {
			align-self: center;
			width: 100%;
		}
		.card__bottom-arrow {
			bottom: -75px;
		}
		.card__bottom {
			margin-top: 125px;
		}
		.card__headline {
			font-size: 39px;
			position: static;
			&:before,
			&:after {
				position: static;
			}
		}
		.card__image {
			width: 100%;
			max-width: 455px;
			margin: 0 auto;
		}
		.card__wrapper {
			width: 100%;
		}
		.card__content {
			padding: 0;
			width: 100%;
		}
	}
	@media (max-width: ${breakpoint.min.to}px) {
		.card__link {
			width: 100%;
		}
	}
`

export const StyledMembership = styled.div`
	padding-top: 145px;
	.card__text {
		color: ${color.main.text2};
		${typography.BodyXXL};
		margin-top: 40px;
		width: 65.7%;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		.card__text {
			width: 100%;
		}
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		padding-top: 40px;
		.card__text {
			margin-top: 30px;
		}
	}
`

export const StyledDocumentation = styled.div`
	display: flex;
	align-items: start;
	.card__text {
		color: ${color.main.text2};
		${typography.BodyL};
	}
	.card__waterfall-agile-wrapper {
		display: flex;
		flex-direction: column;
		width: 68%;
		background: ${color.light.n700};
		padding-top: 174px;
		position: relative;
		padding-bottom: 135px;
	}
	.card__waterfall-agile {
		padding-top: 35px;
		padding-bottom: 147px;
		> div:nth-of-type(1) {
			margin-left: 52px;
			.card__image {
				margin-bottom: 36px;
				margin-top: 25px;
				margin-left: 16px;
			}
		}
		> div:nth-of-type(2) {
			margin-left: 68px;
		}
	}
	.dotted-line--revert {
		transform: scaleX(-1);
	}
	.card__waterfall-agile-question-wrapper {
		display: flex;
		justify-content: center;
		padding-left: 52px;
		img {
			width: 24%;
		}
	}
	.card__waterfall-agile-question {
		color: ${color.main.text2};
		font-size: 14px;
		line-height: 110%;
		text-align: center;
		letter-spacing: 0.3em;
		text-transform: uppercase;
		font-weight: 500;
		max-width: 150px;
		position: relative;
		bottom: 10px;
		margin: 0 10px;
	}
	.card__left {
		width: 32%;
		padding-right: 40px;
		padding-top: 140px;
	}
	h2 {
		padding-bottom: 40px;
	}
	.card__text {
		margin-bottom: 40px;
		padding-right: 48px;
	}
	.card__waterfall-agile {
		display: flex;
		padding-bottom: 0;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
		.card__left {
			width: 100%;
			padding-top: 40px;
			padding-bottom: 80px;
			padding-right: 0;
		}
		.card__waterfall-agile-wrapper {
			padding-bottom: 50px;
		}
		.card__waterfall-agile {
			padding-top: 64px;
			justify-content: space-between;
			> div:nth-of-type(1) {
				margin-left: 0;
			}
			> div:nth-of-type(2) {
				margin-left: 0;
			}
		}
		.card__waterfall-agile-question-wrapper {
			img {
				display: none;
			}
		}
		.card__waterfall-agile-wrapper {
			width: 100%;
			padding-top: 100px;
		}
		.card__bg-grey-mobile {
			position: absolute;
			width: calc(100% + 32px);
			height: calc(100%);
			background: ${color.light.n700};
			top: 0;
			left: -16px;
		}
		.card__waterfall-agile-question-wrapper {
			padding-left: 0;
		}
		.card__waterfall-agile-question {
			bottom: 0;
			max-width: 100%;
		}
		.card__text {
			padding-right: 0;
		}
		.card__link {
			width: 100%;
			text-align: center;
		}
		.card__main {
			padding: 18px !important;
			padding-top: 40px !important;
			padding-bottom: 33px !important;
		}
		.card__headline {
			padding-bottom: 24px !important;
		}
	}
	@media (max-width: ${breakpoint.min.to}px) {
		.card__waterfall-agile {
			flex-direction: column;
		}
	}
`

export const StyledWaterfallAgile = styled.div`
	display: flex;
	flex-direction: column;
	&::after {
		content: "";
		position: absolute;
		top: 16px;
		left: 16px;
		border: 2px solid ${color.main.primary1};
		width: 100%;
		height: calc(100% - 32px);
		z-index: -1;
	}
`

export const StyledMainContent = styled.div`
	padding-top: 80px;
	padding-bottom: 40px;
	font-size: 24px;
	line-height: 36px;
	.card__headline {
		color: ${color.black.default};
		font-size: 48px;
		line-height: 50px;
		padding-bottom: 15px;
		position: relative;
	}
	.card__link {
		margin-bottom: 32px;
	}
	.card__content {
		padding-right: 60px;
	}
	.card__text {
		color: ${color.dark.default};
		padding-bottom: 32px;
	}
	.card__children {
		padding-top: 35px;
		width: 100%;
		margin: auto;
		order: 3;
	}
	@media (max-width: ${breakpoint.min.to}px) {
		.card__link {
			width: 100%;
		}
	}
	@media (max-width: ${breakpoint.default.to}px) {
		.card__headline {
			font-size: 39px;
		}
		.card__content {
			padding: 0;
			width: 100%;
		}
	}
`

export const BlueBorderBg = styled.div`
	position: relative;
	width: 43%;
	margin-bottom: 40px;
	margin-left: 44px;
	display: flex;
	z-index: 1;
	@media (max-width: ${breakpoint.default.to}px) {
		width: 46%;
		margin-left: 0;
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		margin-bottom: 64px;
	}
	@media (max-width: ${breakpoint.min.to}px) {
		width: 100%;
	}
`

export const StyledMission = styled.div`
	background: #ffffff;
	border-radius: 2px;
	box-shadow: 0px 20px 32px rgba(9, 20, 79, 0.05);
	display: flex;
	&::after {
		content: "";
		position: absolute;
		top: ${props => (props.borderType === "bottom" ? "24px" : "16px")};
		left: 16px;
		border: 2px solid ${color.main.primary1};
		width: 100%;
		height: calc(
			100% - ${props => (props.borderType === "bottom" ? "12px" : "32px")}
		);
		z-index: -1;
	}
	.card__image {
		margin-left: 5px;
		line-height: normal;
	}
	.card__headline {
		color: ${color.black.default};
		margin-top: 30px;
		padding-bottom: 12px;
	}
	.card__text {
		color: ${color.main.text2};
		${typography.BodyL};
		margin-bottom: 0;
		padding-right: 0;
	}
	.card__main {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
		padding: 48px;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		margin-right: 0;
		width: 100%;
		&::after {
			display: none;
		}
		.card__main {
			padding: 32px;
		}
	}
	@media (max-width: ${breakpoint.postMin.to}px) {
		.card__main {
			padding: 24px;
		}
	}
`

export const StyledMissionReverse = styled.div`
	display: flex;
	flex-direction: column;
	.card__headline {
		color: ${color.black.default};
		padding: 0 0 25px 0;
		position: relative;
		&:before,
		&:after {
			content: "";
			display: inline-block;
			width: 28px;
			height: 21px;
			position: absolute;
			background-repeat: repeat-x;
			background-size: 100%;
			background-image: url("/css/quote.svg");
		}
		&:before {
			left: -41px;
			top: 14px;
			margin-right: 10px;
		}
		&:after {
			transform: rotate(180deg);
			margin-left: 10px;
			margin-top: 12px;
		}
	}
	.card__text {
		color: ${color.dark.default};
		padding-bottom: 45px;
		margin-top: 15px;
	}
	.card__link {
		${typography.ButtonS};
		color: ${color.main.primary1};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.card__content {
		order: 1;
	}
	.card__image {
		order: 3;
	}
	.card__children {
		order: 2;
	}
	@media (max-width: ${breakpoint.min.to}px) {
		.card__link {
			width: 100%;
		}
	}
	@media (max-width: ${breakpoint.default.to}px) {
		.card__headline {
			position: static;
			&:before,
			&:after {
				position: static;
			}
			&:before {
				display: block;
				margin-bottom: 4px;
				margin-left: 2px;
			}
		}
		.card__text {
			margin-top: 7px;
			padding-bottom: 37px;
		}
	}
`

export const StyledMainList = styled.div`
	width: 30%;
	display: flex;
	align-items: flex-start;
	padding-top: 164px;
	padding-bottom: 190px;
	justify-content: space-between;
	.card__headline {
		color: ${color.main.text1};
		padding-bottom: 16px;
	}
	.card__image {
		height: 111px;
	}
	.card__text {
		color: ${color.main.text2};
		${typography.BodyL};
	}
	.card__content {
		width: 100%;
	}
	.card__children {
		display: none;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: 100%;
		padding: 0;
		background: #fff;
		padding-bottom: 57px;
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		flex-direction: column;
		.card__image {
			margin: 0;
			height: 120px;
		}
	}
`

export const StyledMembershipHelp = styled.div`
	display: flex;
	justify-content: space-between;
	.card__headline {
		color: ${color.main.text1};
		padding-bottom: 14px;
		padding-top: 14px;
	}
	.card__text {
		color: ${color.main.text2};
		${typography.BodyL};
	}
	.card__content {
		width: 100%;
	}
	.card__children {
		display: none;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: 100%;
		padding: 0;
		padding-bottom: 57px;
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		flex-direction: column;
		padding-bottom: 64px;
		.card__image {
			margin: 0;
			height: 120px;
		}
	}
`

export const StyledMember = styled.div`
	padding-top: 74px;
	.card__main {
		padding-left: 2.1%;
	}
	.card__wrapper {
		display: flex;
	}
	.card__headline {
		display: none;
	}
	.card__content {
		font-size: 18px;
		line-height: 28px;
	}
	.card__image {
		padding-top: 22px;
		width: 14.4%;
		margin-right: 11.6%;
		flex-shrink: 0;
		text-align: center;
	}
	.card__text {
		color: ${color.main.text1};
		${typography.BodyL};
		padding-bottom: 16px;
	}
	.card__link {
		display: inline-block;
		position: relative;
		padding-right: 30px;
		${typography.ButtonS};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
		&:after {
			content: "";
			display: inline-block;
			height: 7px;
			width: 16px;
			background-size: 100%;
			background-image: url("/css/link-arrow.svg");
			background-size: contain;
			margin-left: 9px;
			position: relative;
			bottom: 2px;
		}
	}
	&.otherMembers {
		width: 22%;
		margin-right: 3%;
	}
	@media (max-width: ${breakpoint.preMax.to}px) {
		&.otherMembers {
			width: 47%;
			margin-right: 3%;
		}
		.card__main {
			padding-left: 0;
		}
	}
	@media (max-width: ${breakpoint.postDefault.to}px) {
		&.members {
			width: 100%;
			margin-right: 0;
		}
		.card__wrapper {
			flex-direction: column;
		}
		.card__image {
			padding-top: 0;
			width: 30%;
			text-align: left;
			margin-bottom: 12px;
		}
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		margin-bottom: 68px;
		padding-bottom: 26px;
		border-bottom: 1px solid ${color.light.n500};
		&.members {
			width: 100%;
		}
		&.otherMembers {
			width: 100%;
			margin-right: 0;
		}
		.card__image {
			margin-bottom: 55px;
			width: 115px;
			margin-left: 10px;
		}
	}
`

export const StyledStep = styled.div`
	display: flex;
	padding-bottom: 60px;
	.card__headline {
		padding-left: 40px;
		padding-bottom: 10px;
		font-size: 21px;
		line-height: 30px;
	}
	.card__content {
		font-size: 18px;
		line-height: 28px;
		width: 67%;
	}
	.card__image-wrap {
		width: 33%;
		padding-right: 40px;
	}
	.blueUl {
		list-style-type: none;
		padding-left: 40px;
		position: relative;
	}
	.blueLi {
		padding-bottom: 25px;
		&:before {
			content: "";
			background-image: url("/css/check1.svg");
			width: 40px;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;
			background-repeat: no-repeat;
		}
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		flex-direction: column;
		.card__image-wrap,
		.card__content {
			width: 100%;
			padding: 0;
		}
		.card__image {
			display: block;
			margin: auto;
			padding-bottom: 30px;
		}
	}
`

const StyledCard = styled.div``

export default StyledCard
