import React from "react"
import { shape, node } from "prop-types"
import styled from "styled-components"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import Card from "../components/Card"
import key from "../utils/key"
import skin, { color } from "../components/_globalSettings/skin"
import Button from "../components/Link/Button"
import { Heading2 } from "../components/_globalSettings/typography"
import { StyledDocumentation } from "../components/Card/style"
import DottedLine from "../images/dotted-line.svg"
import { breakpoint } from "../components/_globalSettings/var"
import useGlobal from "../store"
import formatFormData from "../utils/formFormatDocumentation"
import ModalForm from "../components/Form/ModalForm"

const StyledCardBlock = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 65px;
	align-items: flex-start;
	.card-block__image {
		padding: 0 55px 25px 0;
	}
	.card-block__body {
		max-width: 500px;
	}
	h3 {
		font-size: 25px;
		line-height: 31px;
		padding-bottom: 8px;
	}
	p {
		font-size: 20px;
		line-height: 160%;
	}
	ul {
		list-style-type: none;
		padding-left: 40px;
		padding-top: 48px;
		li {
			position: relative;
			padding-bottom: 24px;
			&:before {
				content: "▪";
				color: #2548ff;
				position: absolute;
				left: -28px;
				line-height: 32px;
				font-size: 32px;
			}
		}
		strong {
			font-size: 18px;
			line-height: 160%;
		}
	}
`

const MainListWrap = styled.div`
	justify-content: space-between;
	display: flex;
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
		padding-top: 104px;
		padding-bottom: 43px;
	}
`

const CardBlock = ({ children }) => (
	<StyledCardBlock>{children}</StyledCardBlock>
)

CardBlock.propTypes = {
	children: node.isRequired,
}

const Waterfall = ({ pageContext }) => {
	const [globalState, globalActions] = useGlobal()
	return (
		<Layout {...pageContext}>
			<Row colorLeft="#ffffff" colorRight={color.light.n700}>
				<StyledDocumentation>
					<div className="card__left">
						<Heading2>{pageContext.banner.headline}</Heading2>
						<p className="card__text">{pageContext.banner.content}</p>
						<Button
							onClick={() => {
								if (
									globalState.windowDimensions.width >= breakpoint.default.from
								) {
									globalActions.modalOpen(true)
								}
							}}
							href={
								globalState.windowDimensions.width <= breakpoint.default.to
									? "/tvorba-zadani-zadost"
									: ""
							}
							skin={skin.blue}
							className="card__link"
						>
							{pageContext.banner.button.label}
						</Button>
						<ModalForm
							headline={pageContext.helpForm.headline}
							data={pageContext.helpForm.form}
							id="helpFormDocumentation"
							formatFormData={formatFormData}
						/>
					</div>
					<div className="card__waterfall-agile-wrapper">
						<div className="card__bg-grey-mobile" />
						<div className="card__waterfall-agile-question-wrapper">
							<img src={DottedLine} alt="Jak chcete pracovat?" />
							<div className="card__waterfall-agile-question">
								{pageContext.banner.question}
							</div>
							<img
								src={DottedLine}
								alt="Jak chcete pracovat?"
								className="dotted-line--revert"
							/>
						</div>
						<div className="card__waterfall-agile">
							{pageContext.banner.points.map(point => {
								return (
									<Card key={point.image.publicURL} type="mission" {...point} />
								)
							})}
						</div>
					</div>
				</StyledDocumentation>
			</Row>
			<Row wrap="wrap">
				<MainListWrap>
					{pageContext.help.cards.map((card, i) => (
						<Card type="mainList" key={key(i, card.headline)} {...card} />
					))}
				</MainListWrap>
			</Row>
		</Layout>
	)
}

Waterfall.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default Waterfall
