import React from "react"
import { string, node } from "prop-types"
import Image from "../Image"
import A from "../Link"
import { Heading1, Heading4, Heading5 } from "../_globalSettings/typography"
import sanitized from "../../utils/sanitizeHTML"

const PureCard = ({
	image,
	headline,
	content,
	bottomContent,
	children,
	linkArea,
	linkText,
	linkUrl,
	headlineTag,
}) => {
	const headlineOptions = {
		h1: <Heading1>{headline}</Heading1>,
		h2: <h2 className="card__headline">{headline}</h2>,
		h3: <h3 className="card__headline">{headline}</h3>,
		h4: <Heading4 className="card__headline">{headline}</Heading4>,
		h5: <Heading5 className="card__headline">{headline}</Heading5>,
		h6: <h6 className="card__headline">{headline}</h6>,
	}
	return (
		<>
			<div className="card__main">
				<div className="card__wrapper">
					{image && (
						<div className="card__image">
							<Image data={image} alt={headline} />
						</div>
					)}
					{(headline || content || linkArea || linkText || linkUrl) && (
						<div className="card__content">
							{headline && headlineOptions[headlineTag]}
							{content && (
								<p
									className="card__text"
									dangerouslySetInnerHTML={{
										__html: sanitized(content),
									}}
								/>
							)}
							{linkArea}
						</div>
					)}
				</div>
				{linkText && (
					<A href={linkUrl} className="arrow-link">
						<div
							dangerouslySetInnerHTML={{
								__html: sanitized(
									`${linkText}&nbsp;<span className="arrow-link__arrow">→</span>`
								),
							}}
						/>
					</A>
				)}
				{children && <div className="card__children">{children}</div>}
			</div>
			{bottomContent && <div className="card__bottom">{bottomContent}</div>}
		</>
	)
}

export const pureCardProps = {
	headline: string,
	content: string,
	linkText: string,
	linkUrl: string,
	headlineTag: string,
	children: node,
}

PureCard.propTypes = {
	...pureCardProps,
}

PureCard.defaultProps = {
	link: undefined,
	image: undefined,
	headline: undefined,
	content: undefined,
	children: undefined,
	headlineTag: "h4",
}

export default PureCard
