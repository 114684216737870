import React from "react"
import { string, shape, node } from "prop-types"
import A from "../Link"
import Image from "../Image"
import skin from "../_globalSettings/skin"
import Button from "../Link/Button"

const Step = ({ image, headline, content, children, link, button }) => {
	return (
		<>
			{image && (
				<div className="card__image-wrap">
					<Image data={image} alt={headline} className="card__image" />
				</div>
			)}
			<div className="card__content">
				{headline && <h3 className="card__headline">{headline}</h3>}
				<p className="card__text">{content}</p>
				<div className="card__children">{children}</div>
				{button && (
					<Button href={button.url} skin={skin.blue} className="card__link">
						{button.label}
					</Button>
				)}
				{link && (
					<A href={link.url} className="card__link">
						{link.label}
					</A>
				)}
			</div>
		</>
	)
}

export const stepProps = {
	headline: string,
	content: string,
	image: shape({
		publicURL: string,
	}),
	link: shape({
		url: string,
		label: string,
	}),
	children: node,
}

Step.propTypes = {
	...stepProps,
}

Step.defaultProps = {
	link: undefined,
	image: undefined,
	headline: undefined,
	content: undefined,
	children: undefined,
}

export default Step
