import React from "react"
import { string } from "prop-types"
import {
	StyledMain,
	StyledDocumentation,
	StyledWaterfallAgile,
	StyledMission,
	StyledMissionReverse,
	StyledMember,
	StyledMembership,
	StyledMembershipHelp,
	StyledMainList,
	StyledStep,
	StyledMainContent,
	BlueBorderBg,
} from "./style"
import PureCard, { pureCardProps } from "./PureCard"
import Step from "./Step"

const Card = ({ type, style, className, id, bottomContent, ...rest }) => {
	const styledCard = {
		main: (
			<StyledMain style={style} className={className}>
				<PureCard {...rest} headlineTag="h1" bottomContent={bottomContent} />
			</StyledMain>
		),
		documentation: (
			<StyledDocumentation style={style} className={className}>
				<PureCard {...rest} headlineTag="h2" />
			</StyledDocumentation>
		),
		waterfallAgile: (
			<BlueBorderBg>
				<StyledWaterfallAgile style={style} className={className}>
					<PureCard {...rest} headlineTag="h5" />
				</StyledWaterfallAgile>
			</BlueBorderBg>
		),
		mainList: (
			<StyledMainList style={style} className={className}>
				<PureCard {...rest} headlineTag="h5" />
			</StyledMainList>
		),
		mission: (
			<BlueBorderBg>
				<StyledMission style={style} className={className} borderType="bottom">
					<PureCard {...rest} headlineTag="h5" />
				</StyledMission>
			</BlueBorderBg>
		),
		missionReverse: (
			<StyledMissionReverse style={style} className={className}>
				<PureCard {...rest} />
			</StyledMissionReverse>
		),
		member: (
			<StyledMember style={style} className={className} id={id}>
				<PureCard {...rest} />
			</StyledMember>
		),
		membership: (
			<StyledMembership style={style} className={className} id={id}>
				<PureCard {...rest} headlineTag="h1" />
			</StyledMembership>
		),
		membershipHelp: (
			<StyledMembershipHelp style={style} className={className} id={id}>
				<PureCard {...rest} headlineTag="h4" />
			</StyledMembershipHelp>
		),
		step: (
			<StyledStep style={style} className={className}>
				<Step {...rest} />
			</StyledStep>
		),
		mainContent: (
			<StyledMainContent style={style} className={className}>
				<PureCard {...rest} headlineTag="h3" />
			</StyledMainContent>
		),
	}
	return styledCard[type] || null
}

export const cardProps = pureCardProps

Card.propTypes = {
	...cardProps,
	type: string.isRequired,
}

export default Card
